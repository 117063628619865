import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleOpenForm, toggleShowVerification, toggleShowRent } from 'redux/Actions/ui-actions';
import { toggleAddPinCode, toggleAddStatusVerification } from 'redux/Actions/form-actions';

import styles from './verification.module.scss';
import ArrowLeft from '../../../../assets/arrow-left.svg';
import ArrowRight from '../../../../assets/arrow-right.svg';
import Bird from '../../../../assets/checkbox-bird.svg';
import Button from '../../../Button/button';
import ButtonAgain from '../Button/button-again';

const Verification = (props) => {
  const {
    openFormFUNC,
    showVerificationFUNC,
    showRentFUNC,
    addPinCodeFUNC,
    addStatusVerificationFUNC,
    phoneNumber,
    code,
    statusVerification,
  } = props;

  const [clickedAgain, setClickedAgain] = useState(false);
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm();

  const onSubmitPin = () => {
    const url = 'https://api.bergregions.pl/api/dev/deposit/reservation/touch/verify';

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ phoneNumber, code }),
    })
      .then((result) => {
        result.text();
        addStatusVerificationFUNC(result.status);
      })
      // eslint-disable-next-line no-unused-vars
      .catch(() => {
        // handle error
      });
  };

  if (code.length === 4) {
    onSubmitPin();
  }

  const handleNextStep = () => {
    if (statusVerification === 200) {
      showVerificationFUNC();
      showRentFUNC();
    }
  };

  const onSubmitVerificationAgain = () => {
    setClickedAgain(!clickedAgain);

    axios
      .post('https://api.bergregions.pl/api/v1/deposit/reservation/touch/send-verification', {
        phoneNumber,
      })
      .then((response) => response.data())
      // eslint-disable-next-line no-unused-vars
      .catch(() => {
        // handle error
      });
  };

  const handleBackView = () => {
    openFormFUNC();
    showVerificationFUNC();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.verification}>
      <header>
        <h2 className={styles.verification__title}>{t('verificationTitle')}</h2>
      </header>
      <p className={styles.verification__paragraph}>
        {t('verificationMessage1/2')}
        <span className={styles.verification__paragraphBold}>
          {phoneNumber
            .replace(/[^\dA-Z]/g, '')
            .replace(/(.{3})/g, '$1 ')
            .trim()}
        </span>
        ){t('verificationMessage2/2')}
      </p>
      <p className={styles.verification__paragraph}>{t('verificationMessage3')}</p>
      <div className={styles.verification__inputBox}>
        <p className={styles.verification__paragraphBold}>{t('verificationPin')}</p>
        <form className={styles.verification__form}>
          <input
            className={
              statusVerification === 404
                ? styles.verification__formInputError
                : styles.verification__formInput
            }
            name="code"
            id="code"
            type="tel"
            autoComplete="off"
            value={code}
            onChange={addPinCodeFUNC}
            maxLength="4"
            ref={register({ required: true, min: 4, pattern: { value: /^[0-9\b]+$/ } })}
          />
        </form>
        <div className={styles.verification__status}>
          {statusVerification === 200 && (
            <p className={styles.verification__statusOk}>
              <img className={styles.verification__statusBird} src={Bird} alt="Status OK" />
              {t('verificationPinStatusOK')}
            </p>
          )}
          {statusVerification === 400 && (
            <p className={styles.verification__statusFailed}>{t('verificationPinStatusFailed')}</p>
          )}
          {statusVerification === 404 && (
            <p className={styles.verification__statusFailed}>{t('verificationPinStatusFailed')}</p>
          )}
        </div>
      </div>
      <div className={styles.verification__info}>
        <p className={styles.verification__infoParagraphBold}>{t('verificationPinError')}</p>
        <p className={styles.verification__infoParagraph}>{t('verificationPinError2')}</p>
        {clickedAgain ? (
          <ButtonAgain clicked onPress={onSubmitVerificationAgain}>
            {t('verificationPinButton')}
          </ButtonAgain>
        ) : (
          <ButtonAgain onPress={onSubmitVerificationAgain}>
            {t('verificationPinButton')}
          </ButtonAgain>
        )}
      </div>

      <div className={styles.buttons}>
        <Button secondary onPress={handleBackView}>
          <img className={styles.buttons__arrowLeft} src={ArrowLeft} alt="Strzałka w lewo" />
          {t('formButtonBack')}
        </Button>
        <Button type="submit" onPress={handleSubmit(handleNextStep)}>
          {t('formButtonForward')}
          <img className={styles.buttons__arrowRight} src={ArrowRight} alt="Strzałka w prawo" />
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  openFormFUNC: () => dispatch(toggleOpenForm()),
  showVerificationFUNC: () => dispatch(toggleShowVerification()),
  showRentFUNC: () => dispatch(toggleShowRent()),
  addStatusVerificationFUNC: (data) => dispatch(toggleAddStatusVerification(data)),
  addPinCodeFUNC: (evt) => dispatch(toggleAddPinCode(evt)),
});

const mapStateToProps = (state) => ({
  phoneNumber: state.Form.phoneNumber,
  code: state.Form.code,
  statusVerification: state.Form.statusVerification,
});

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
